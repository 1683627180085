<template>
    <div
        class="banks-card multiple-choice-grid"
        :class="{
            'is-section-opened': bankContentItem.isSectionOpened,
            'is-skipped': bankContentItem.isSkipped,
            'is-invalid': bankContentItem.isContentValid === false,
            'is-valid': bankContentItem.isContentValid === true,
        }"
    >
        <div class="banks-card__header">
            <div class="banks-card__header--bank">
                <img :src="bankContentItem.bank.icon_path" alt="">
                <span>{{ bankContentItem.bank.name }}</span>
            </div>

            <div class="banks-card__header--right">
                <a href="" v-if="bankContentItem.isSkipped" @click.prevent="bankContentItem.unSkip()">{{ $t('Reply for this bank') }}</a>
                <a href="" v-if="!bankContentItem.isSkipped && bankContentItem.isSkippable()" @click.prevent="bankContentItem.skip()">{{ $t('Skip this bank') }}</a>
            </div>
        </div>

        <div class="multiple-choice-grid__table">
            <div class="grid-table__header">
                <div class="grid-table__header--item"></div>
                <div
                    class="grid-table__header--item"
                    v-for="(option, index) in bankContentItem.options"
                    :key="index"
                >{{ option.label }}</div>
            </div>
            <div class="grid-table__body">
                <template
                    v-for="(rowItem, index) in bankContentItem.rowItems"
                    :key="index"
                >
                    <el-radio-group
                        class="grid-table__body--row"
                        v-model="rowItem.answer"
                        @change="bankContentItem.onAnswer()"
                    >
                        <div class="row-item">
                            <span>{{ rowItem.row.label }}</span>
                        </div>
                        <template
                            v-for="(option, index) in rowItem.options"
                            :key = index
                        >
                            <div class="row-item">
                                <el-radio :label="option.id">{{ option.label }}</el-radio>
                            </div>
                        </template>
                    </el-radio-group>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CQMultipleChoiceGridAnswer",
    props: {
        bankContentItem: Object
    }
}
</script>

<style lang="scss">
.multiple-choice-grid {
    overflow: hidden;

    .banks-card__header {
        border-bottom: 0;
    }

    .banks-card__header--right {
        margin-top: 10px;
        a {
            font-weight: 400;
            font-size: 12px;
            line-height: 158.02%;
            color: #435BF4;
        }
    }

    &__table {
        background: #fff;
        .grid-table {
            &__header {
                display: flex;
                &--item {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 15px;
                    color: #666666;
                    max-width: 210px;
                    width: 70%;
                    text-align: center;
                    padding: 18px 0;
                    &:first-child {
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }
            &__body {
                &--row {
                    display: flex;
                    &:nth-child(odd) {
                        background: #FAFAFC;
                    }
                    &:hover {
                        background: #F6F7FE;
                        .row-item {
                            span {
                                color: #435BF4;
                            }
                        }
                    }
                    .row-item {
                        max-width: 210px;
                        width: 70%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 50px;
                        span {
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 15px;
                            color: #666666;
                            margin-left: 52px;
                            transition: .2s ease-in-out;
                        }
                        .el-radio__inner {
                            width: 18px;
                            height: 18px;
                            border-color: #a2a2a2;
                            &:after {
                                width: 6px;
                                height: 6px;
                            }
                        }
                        .el-radio__input.is-checked .el-radio__inner {
                            border-color: #435BF4;
                        }
                        .el-radio__input, .el-radio__inner {
                            margin: 0;
                        }
                        .el-radio__label {
                            display: none;
                        }
                        &:first-child {
                            max-width: 100%;
                            width: 100%;
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
    }

    &.is-skipped {
        .multiple-choice-grid__table {
            position: relative;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: #ffffffb5;
            }
        }

        .banks-card__header:after {
            display: none;
        }

        .banks-card__header--right a {
            font-weight: 700;
        }
    }
}
</style>
