<template>
    <el-dialog
        v-model="popupVisible"
        fullscreen
        :show-close="true"
        :close-on-press-escape="false"
        append-to-body
        :custom-class="`feedback-reply-popup${forLoggedIn ? ' for-logged-in' : ''}`"
        :before-close="handlePopupClose"
    >
        <div class="feedback-reply-popup__loading-wrapper" v-if="feedbackDataContainer.submittingLoading">
            <CustomLoader />
        </div>

        <template #title>
            <div class="reply-popup__header header">
                <div class="reply-popup__header--title">
                    <template v-if="feedbackDataContainer.isCompleted">
                        <h4>{{ $t("Reply Summary") }}</h4>
                        <p>{{ $t("Thank you for your valuable inputs, you can see more details on PLATFORMNAME!", {platformName: platformConfigs.platformName}) }}</p>
                    </template>
                    <template v-else>
                        <h4>{{ $t("Please Provide Feedback") }}</h4>
                        <p>{{
                                $t("Score the bank, product area and phase. Add comments for additional inputs. Your opinion matters.")
                            }}</p>
                    </template>
                </div>

                <template v-if="forLoggedIn">
<!--                    <SelectLanguage class="auth-select-language" is-auth />-->
                    <UserDropdown />
                </template>
            </div>
        </template>

        <BusinessFeedbackSubmitted
            :feedbackDataContainer="feedbackDataContainer"
            v-if="feedbackDataContainer.isCompleted"
            @handleBack="handleClickBack"
        />

        <div v-else class="reply-popup__body">

            <div class="reply-popup__product-area">
                <ProductAreaInvolved
                    :productAreas="feedbackDataContainer.productAreas"
                    :activeProductArea="questionDetails.productArea"
                    @popupClose="handlePopupClose" />
            </div>

            <div class="reply-popup__container" :class="{'question-not-required': !questionDetails.isRequired}">
                <QuestionDetails
                    :feedbackDataContainer="feedbackDataContainer"
                    :questionDetails="questionDetails"
                />

                <div class="reply-container">
                    <div class="question-details__actions">
                        <div class="question-details__actions--left">
                            <template v-if="questionDetails.isRequired">
                                <img src="/media/buying/icons/info-icon-gray.svg" alt="">
                                <span>{{ $t('This question is marked mandatory and must be answered before continuing to the next question.') }}</span>
                            </template>
                        </div>
                        <div class="question-details__actions--right">
                            <a v-if="!questionDetails.isRequired" href="" @click.prevent="handleSkipQuestion">{{ $t('Skip this question') }}</a>
                            <button
                                class="btn main-btn"
                                @click="handleNextQuestion"
                                :disabled="feedbackDataContainer.submittingLoading"
                            >{{ $t('Next Question') }}
                            </button>
                        </div>
                    </div>

                    <div class="banks-session">
                        <div class="banks-session__title">
                            {{ $t("All banks in session") }}
                            <span>{{ questionDetails.replyContentContainer.getItemsLength() }}</span>
                        </div>

                        <div v-if="questionDetails.isMultipleSelect" class="select-multiple-answers-info">
                            {{ $t("You can select multiple answers") }}
                        </div>

                        <!--          <TransitionGroup tag="div" name="fade" class="banks-session__wrapper">-->
                        <!--            <div v-for="bank in questionDetails.replyContent" class="item" :key="bank.bank.id">-->
                        <!--              <CQScoreImportanceAnswer-->
                        <!--                :bankContentItem="bank"-->
                        <!--                @onTriggerCommentAction="handleOpenCommentModal"-->
                        <!--              />-->
                        <!--            </div>-->
                        <!--          </TransitionGroup>-->

                        <div class="multiple-choice-answer">
                            <div class="row">
                                <div
                                    v-for="(bank, index) in questionDetails.replyContentContainer.items"
                                    class="item mt-3 col-lg-12"
                                    :key="bank.bank.id"
                                    :ref="`bankItem_${index}`"
                                >
                                    <CQScoreImportanceAnswer
                                        v-if="questionDetails.type === FeedbackSessionQuestionsTypeEnum.SCORE_IMPORTANCE"
                                        :bankContentItem="bank"
                                        @onTriggerCommentAction="triggerCommentAction"
                                    />
                                    <CQTextOnlyAnswer
                                        v-if="questionDetails.type === FeedbackSessionQuestionsTypeEnum.TEXT_ONLY"
                                        :bankContentItem="bank" />
                                    <CQMultipleChoiceAnswer
                                        v-if="questionDetails.type === FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE"
                                        :bankContentItem="bank"
                                        :isMultipleSelect="questionDetails.isMultipleSelect"
                                    />
                                    <CQMultipleChoiceGridAnswer
                                        v-if="questionDetails.type === FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE_GRID"
                                        :bankContentItem="bank"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <div class="reply-popup__footer">
                <a :href="platformConfigs.platformDomain" target="_blank">{{ platformConfigs.platformDomain }}</a>
                <div class="reply-popup__footer--links">
                    <a :href="platformConfigs.platformLinks.Terms" target="_blank">
                        {{ $t("Terms & Conditions") }}
                    </a>
                    <span class="footer-divider"></span>
                    <a :href="platformConfigs.platformLinks.Privacy" target="_blank">
                        {{ $t("Privacy Policy") }}
                    </a>
                </div>
            </div>
        </template>
    </el-dialog>

    <CommentActionPopup
        v-if="showCommentActionPopup"
        :visibility="showCommentActionPopup"
        :commentPopupData="commentPopupData"
        @onClose="handleCloseCommentPopup"
        @onCommentAction="handleCommentAction"
    />
</template>

<script>
import { diagLog } from "@/core/helpers/GlobalHelper";
import ProductAreaInvolved from "@/buying-teams/pages/business/feedbacks/feedback-reply/ProductAreaInvolved";
import QuestionDetails from "@/buying-teams/pages/business/feedbacks/feedback-reply/QuestionDetails";
import CQScoreImportanceAnswer
    from "@/buying-teams/pages/business/feedbacks/feedback-reply/custom-question/CQScoreImportanceAnswer";
import CommentActionPopup from "@/buying-teams/pages/business/feedbacks/feedback-reply/CommentActionPopup";
import ActionsTypeEnum from "@/store/enums/ActionsTypeEnum";
import NotificationService from "@/buying-teams/services/NotificationService";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import BusinessFeedbackSubmitted from "./BusinessFeedbackSubmitted";
import ReplyActionTypeEnum from "@/store/enums/ReplyActionTypeEnum";
import UserDropdown from "@/layout/header/UserDropdown";
import CQMultipleChoiceAnswer
    from "@/buying-teams/pages/business/feedbacks/feedback-reply/custom-question/CQMultipleChoiceAnswer";
import CQTextOnlyAnswer from "@/buying-teams/pages/business/feedbacks/feedback-reply/custom-question/CQTextOnlyAnswer";
import CQMultipleChoiceGridAnswer
    from "@/buying-teams/pages/business/feedbacks/feedback-reply/custom-question/CQMultipleChoiceGridAnswer";
import { FeedbackSessionQuestionsTypeEnum } from "@/store/enums/FeedbackSessionQuestionsTypeEnum";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";
import { PlatformConfigsModel } from "@/store/models/shared/config/PlatformConfigsModel";
import SelectLanguage from "@/buying-teams/shared-components/utils/SelectLanguage";

export default {
    name: "FeedbackReplyPopup",
    components: {
        SelectLanguage,
        CQMultipleChoiceGridAnswer,
        CQTextOnlyAnswer,
        CQMultipleChoiceAnswer,
        UserDropdown,
        CustomLoader,
        BusinessFeedbackSubmitted,
        CommentActionPopup,
        CQScoreImportanceAnswer,
        QuestionDetails,
        ProductAreaInvolved
    },
    props: {
        popupVisible: {
            type: Boolean,
            default: false
        },
        feedbackDataContainer: {
            type: Object
        },
        forLoggedIn: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            platformConfigs: new PlatformConfigsModel(PlatformTypeEnum.BUSINESS),
            showCommentActionPopup: false,
            commentPopupData: null,
            ReplyActionTypeEnum,
            FeedbackSessionQuestionsTypeEnum
        };
    },
    emits: ["onClose", "handleSaveOrSkip"],
    mounted() {
        diagLog("feedbackDataContainer", this.feedbackDataContainer, { qs: this.feedbackDataContainer.getActiveQuestion() });
    },
    methods: {
        handlePopupClose() {
            if (this.feedbackDataContainer.isCompleted) {
                this.$emit("onClose");
                return;
            }
            NotificationService.swalConfirmDialog({
                title: this.$t("Leave Feedback Session"),
                text: this.$t("Are you sure you want to leave this feedback session?"),
                confirmButtonText: this.$t("Leave"),
                cancelButtonText: this.$t("Continue Session"),
                customClass: "leave-feedback-session registered-user"
            }).then((res) => {
                if (res.value) {
                    this.$emit("onClose");
                }
            });
        },
        triggerCommentAction(data) {
            if (data.actionType === ActionsTypeEnum.REMOVE) {
                this.handleCommentAction({ data });
            } else {
                this.showCommentActionPopup = true;
                this.commentPopupData = data;
            }
        },
        handleCommentAction({ data, comment }) {
            switch (data.actionType) {
                case ActionsTypeEnum.ADD:
                case ActionsTypeEnum.EDIT:
                    data[data.dataType].addComment(comment);
                    break;
                case ActionsTypeEnum.REMOVE:
                    NotificationService.swalConfirmDialog({
                        title: this.$t("Confirm deletion"),
                        text: this.$t("Are you sure, you want to delete this comment?"),
                        showCancelButton: true,
                        confirmButtonText: this.$t("Delete"),
                        customClass: "delete-comment",
                        cancelButtonText: this.$t("Cancel")
                    }).then((result) => {
                        if (result.value) {
                            data[data.dataType].deleteComment();
                        }
                    });
                    break;
                default:
                    console.error(`comment action type ${data.actionType} is not declared`);
                    break;
            }

            this.handleCloseCommentPopup();
        },
        handleCloseCommentPopup() {
            this.showCommentActionPopup = false;
            this.commentPopupData = null;
        },
        scrollToInvalidElement(validationInfo) {
            const invalidElement = this.$refs[`bankItem_${validationInfo.invalidBankIndex}`];
            if (invalidElement) {
                invalidElement[0].scrollIntoView({ behavior: "smooth", block: "center" });
            }
        },
        async handleNextQuestion() {
            this.feedbackDataContainer.validateActiveQuestion()
                .then(async res => {
                    if (res) {
                        let { payload, isAllBanksSkipped } = this.feedbackDataContainer.getAnswers();
                        if (isAllBanksSkipped) {
                            let params = {
                                survey_id: this.feedbackDataContainer.id,
                                question_id: this.questionDetails.id,
                                always: false
                            };
                            await this.handleSaveOrSkip(ReplyActionTypeEnum.SKIP, params);
                        } else {
                            await this.handleSaveOrSkip(ReplyActionTypeEnum.REPLY, payload);
                        }
                    }
                })
                .catch(validationInfo => {
                    console.log("handleSaveAnswer catch : ", validationInfo);
                    this.scrollToInvalidElement(validationInfo);
                });
        },
        async handleSkipQuestion() {
            NotificationService.swalConfirmDialog({
                title: this.$t("Skip question?"),
                text: this.$t("Are you sure, you want to skip this question?"),
                showCancelButton: true,
                confirmButtonText: this.$t("Skip"),
                customClass: "delete-comment",
                cancelButtonText: this.$t("Cancel")
            }).then(async (result) => {
                if (result.value) {
                    let params = {
                        survey_id: this.feedbackDataContainer.id,
                        question_id: this.questionDetails.id,
                        always: false
                    };

                    await this.handleSaveOrSkip(ReplyActionTypeEnum.SKIP, params);
                }
            });
        },
        async handleSaveOrSkip(action, data) {
            this.$emit("handleSaveOrSkip", { action, data });
        },
        handleClickBack() {
            this.$emit("onClose");
        }
    },
    computed: {
        questionDetails() {
            return this.feedbackDataContainer.getActiveQuestion();
        }
    }
};
</script>
<style lang="scss">
.feedback-reply-popup {
    background: #F6F6F6;
    display: flex;
    flex-direction: column;

    &.for-logged-in {
        .el-dialog__close {
            display: none;
        }

        .reply-popup__header--title {
            margin-right: 10px;
        }

        @media (min-width: 576px) {
            .reply-popup__header--title {
                width: calc(100% - 296px);
            }
        }
    }

    .el-dialog__header {
        padding: 42px 30px 0 30px;
        max-width: 1800px;
        margin: 0 auto;
        width: 100%;
    }

    .el-dialog__body {
        padding: 30px 66px 10px 66px;
        max-width: 1800px;
        margin: 0 auto;
        width: 100%;
    }

    .el-dialog__footer {
        margin-top: auto;
        background: #435BF4;
        padding: 15px 42px;

        a {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            letter-spacing: 0.1px;
            color: #FFFFFF;
        }
    }

    &__loading-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #d9d9d973;
        backdrop-filter: blur(3px);
        z-index: 999999;
    }

    .reply-popup {
        &__header {
            position: revert;
            padding: 0 !important;
            height: revert;
            box-shadow: none;

            h4 {
                font-weight: 500;
                font-size: 32px;
                line-height: 109%;
                letter-spacing: -0.05em;
                color: rgba(0, 0, 0, 0.8);
                margin-bottom: 12px;
            }

            p {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #9CA0AB;
            }
        }

        &__body {
            display: flex;
        }

        &__container {
            width: calc(100% - 242px);
            padding-bottom: 50px;

            .banks-session {
                margin-top: 0;

                &__title {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #666666;
                    display: flex;
                    align-items: center;
                    padding-left: 10px;
                    min-height: 35px;

                    span {
                        background: #ECECEC;
                        border-radius: 4px;
                        padding: 2px 10px;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 15px;
                        color: #000000;
                        margin-left: 10px;
                    }
                }

                &__wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                }

                .select-multiple-answers-info {
                    font-style: italic;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 26px;
                    letter-spacing: 0.1px;
                    color: #435BF4;
                    margin-left: 10px;
                    margin-top: 4px;
                    margin-bottom: 12px;
                }
            }

            &.question-not-required {
                .banks-session {
                    margin-top: -30px;
                }
            }
        }

        &__product-area {
            width: 242px;
            padding-right: 26px;
        }

        &__footer {
            display: flex;
            justify-content: space-between;
            max-width: 1800px;
            margin: 0 auto;

            a:hover {
                text-decoration: underline !important;
            }

            &--links {
                display: flex;
                align-items: center;
            }

            .footer-divider {
                display: block;
                height: 21px;
                width: 1px;
                background: #fff;
                margin: 0 25px;
            }
        }
    }
}

.swal2-container {
    z-index: 99999;
}

.swal2-popup.delete-comment {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 26px 16px;
    width: 497px;

    .swal2-header {
        padding: 0;
        align-items: flex-start;

        h2 {
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;
            color: #000000;
            margin-bottom: 0;
            text-align: left;
        }

        .swal2-icon {
            display: none !important;
        }
    }

    .swal2-content {
        padding: 0;
        text-align: left;
    }

    .swal2-html-container {
        margin-top: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 178.52%;
        color: #434343;
        opacity: 0.6;
    }

    .swal2-actions {
        margin: 0;
        justify-content: flex-start;
        margin-top: 25px;
        gap: 21px;

        button {
            margin: 0;
            transition: .3s ease-in-out;

            &.swal2-confirm {
                background: rgba(226, 45, 33, 0.1);
                border-radius: 59px;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #E22D21;
                padding: 15px 18px;
            }

            &.swal2-cancel {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: rgba(67, 67, 67, 0.8);
                background: transparent;
                padding: 0;
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

/* 1. declare transition */
.fade-move,
.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: scaleY(0.01) translate(30px, 0);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
    position: absolute;
}

.swal2-modal.leave-feedback-session {
    width: 491px;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 13px;

    .swal2-icon {
        display: none !important;
    }

    .swal2-header {
        padding: 0;
        margin-bottom: 12px;
    }

    .swal2-title {
        width: 100%;
        margin-bottom: 0;
        padding: 0;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #101010;
    }

    .swal2-content {
        padding: 0;
    }

    .swal2-html-container {
        margin-top: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #9CA0AB;
        text-align: left;
    }

    .swal2-actions {
        margin-top: 20px;
        margin-bottom: 0;
        justify-content: flex-start;
        gap: 22px;

        button {
            margin: 0;
            padding: 10px 30px;
            font-weight: 400;
            font-size: 16px;
            line-height: 158.02%;
            border-radius: 300px;
            background: transparent;
            transition: .3s;

            &:hover {
                opacity: 0.6;
            }

            &.swal2-confirm {
                background: #FEF4F4;
                color: #E22D21;
            }

            &.swal2-cancel {
                color: #435BF4;
                padding: 0;

                &:hover {
                    background: transparent;
                }
            }
        }
    }
}

@media (max-width: 1440px) {
    .feedback-reply-popup {
        .el-dialog__body {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}

@media (max-width: 1200px) {
    .feedback-reply-popup {
        .el-dialog__body {
            padding-left: 20px;
            padding-right: 20px;
        }

        .el-dialog__header {
            padding: 30px 20px 0 20px;
        }

        .reply-popup__product-area {
            width: 217px;
        }

        .reply-popup__container {
            width: calc(100% - 217px);
        }
    }
}

@media (max-width: 1200px) {
    .feedback-reply-popup {
        .reply-popup__body {
            display: flex;
            flex-direction: column;

            .reply-popup__product-area {
                width: 100%;
                padding: 0;
                border-bottom: 1px solid #E5E5E5;
            }

            .reply-popup__container {
                width: 100%;
            }
        }

        .reply-popup__container {
            &.question-not-required .banks-session {
                margin-top: 0;
            }
            .reply-container {
                display: flex;
                flex-direction: column-reverse;
                margin-top: 15px;
            }
        }
    }
}

@media (max-width: 768px) {
    .feedback-reply-popup {
        .reply-popup__header {
            h4 {
                font-size: 20px;
                margin-bottom: 5px;
            }

            p {
                font-size: 14px;
            }
        }

        .el-dialog__header {
            padding: 20px 14px 0 14px;
        }

        .el-dialog__body {
            padding: 0 14px 20px 14px;
        }

        .el-dialog__footer {
            padding-left: 14px;
            padding-right: 14px;

            a {
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 768px) {
    .feedback-reply-popup {
        .reply-popup__footer {
            flex-direction: column;
            align-items: center;
            gap: 10px;
        }
    }
}
</style>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Quantico:wght@400;700&display=swap');
</style>
