<template>
    <div
        class="banks-card"
        :class="{
          'is-section-opened': bankContentItem.isSectionOpened,
          'is-skipped': bankContentItem.isSkipped,
          'is-invalid': bankContentItem.isContentValid === false,
          'is-valid': bankContentItem.isContentValid === true,
        }"
    >
        <div class="banks-card__header">
            <div class="banks-card__header--content">
                <div class="banks-card__header--bank">
                    <img :src="bankContentItem.bank.icon_path" alt="">
                    <span>{{ bankContentItem.bank.name }}</span>
                </div>

                <div
                    v-if="bankContentItem.isGroupRatingVisible()"
                    class="banks-card__header--right"
                >
                    <div class="stars-container">
                        <div class="stars-wrapper">
                            <div class="stars-wrapper__title">{{ $t("Score") }}</div>
                            <template v-if="bankContentItem.isChildTouched">
                                <star-rating
                                    :read-only="bankContentItem.isChildTouched"
                                    class="justify-content-center"
                                    active-color="#dcdbff5c"
                                    :star-size="28"
                                    inactive-color="#dcdbff5c"
                                    :rating="0"
                                    :round-start-rating="false"
                                    :show-rating="false"
                                    :increment="1"
                                />
                            </template>
                            <template v-else>
                                <star-rating
                                    :read-only="bankContentItem.isChildTouched"
                                    class="justify-content-center"
                                    active-color="#FFAD50"
                                    :star-size="28"
                                    inactive-color="#dcdbff5c"
                                    :rating="bankContentItem.score"
                                    :round-start-rating="false"
                                    :show-rating="false"
                                    :increment="1"
                                    @update:rating="bankContentItem.rate(RatingTypesEnum.SCORE, $event)"
                                />
                            </template>
                        </div>
                        <div class="stars-wrapper">
                            <div class="stars-wrapper__title">{{ $t("Importance") }}</div>
                            <template v-if="bankContentItem.isChildTouched">
                                <star-rating
                                    :read-only="bankContentItem.isChildTouched"
                                    class="justify-content-center"
                                    active-color="#F2F2F2"
                                    :star-size="28"
                                    inactive-color="#F2F2F2"
                                    :rating="0"
                                    :round-start-rating="false"
                                    :show-rating="false"
                                    :increment="1"
                                />
                            </template>
                            <template v-else>
                                <star-rating
                                    :read-only="bankContentItem.isChildTouched"
                                    class="justify-content-center"
                                    active-color="#FFAD50"
                                    :star-size="28"
                                    inactive-color="#F2F2F2"
                                    :rating="bankContentItem.importance"
                                    :round-start-rating="false"
                                    :show-rating="false"
                                    :increment="1"
                                    @update:rating="bankContentItem.rate(RatingTypesEnum.IMPORTANCE, $event)"
                                />
                            </template>
                        </div>
                    </div>

                    <template v-if="bankContentItem.isCommentAllowed()">
                        <el-dropdown v-if="bankContentItem.comment" trigger="click" placement="bottom-end">
                            <button class="comment-btn btn-comment-exist">
                                <img src="/media/buying/icons/comment-exist-icon.svg" width="20" class="my-2" alt="">
                            </button>
                            <template #dropdown>
                                <el-dropdown-menu class="comment-dropdown">
                                    <el-dropdown-item
                                        @click="$emit('onTriggerCommentAction', {
                                          actionType: ActionsTypeEnum.EDIT,
                                          bankContentItem: bankContentItem,
                                          country: null,
                                          dataType: 'bankContentItem'
                                        })"
                                    >
                                        <img src="/media/buying/icons/edit.svg" class="me-2" height="13" width="14"
                                             alt="">
                                        {{ $t("Edit") }}
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                        @click="$emit('onTriggerCommentAction', {
                                          actionType: ActionsTypeEnum.REMOVE,
                                          bankContentItem: bankContentItem,
                                          country: null,
                                          dataType: 'bankContentItem'
                                        })"
                                    >
                                        <img src="/media/buying/icons/delete.svg" class="me-2" height="13" width="14"
                                             alt="">
                                        {{ $t("Delete") }}
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>

                        <button
                            v-else
                            class="comment-btn btn-no-comment"
                            @click="$emit('onTriggerCommentAction', {
                              actionType: ActionsTypeEnum.ADD,
                              bankContentItem: bankContentItem,
                              country: null,
                              dataType: 'bankContentItem'
                            })"
                        >
                            <img src="/media/buying/icons/comment-blue.svg" width="16" alt="">
                        </button>
                    </template>
                    <div v-else class="comment-btn-blank"></div>
                </div>
            </div>

            <div class="item-error-message mt-5"
                 v-if="(bankContentItem.isSectionOpened && bankContentItem.isContentValid === false) && showErrorMessage">
                <img src="/media/buying/icons/error-icon.svg" alt="error-icon" class="me-3">
                <span>{{ $t("Hey, it seems you either missed or didn't complete giving score to some of the countries.")
                    }}</span>
                <img src="/media/buying/icons/close-red.svg" alt="close-red" class="item-error-message__close"
                     @click="showErrorMessage = false">
            </div>
        </div>
        <Transition>
            <div class="banks-card__collapse" v-if="bankContentItem.isSectionOpened">
                <div
                    class="banks-card__country"
                    :class="{'isValid': country.isValid(), 'is-skipped': country.isSkipped}"
                    v-for="(country, index) in bankContentItem.bankCountries"
                    :key="index"
                >
                    <div class="banks-card__country--bank">
                        <div
                            class="bank-icon"
                            :class="{
                              'isValid': country.isTouched && country.isValid(),
                              'isInvalid': country.isTouched && !country.isValid()
                            }"
                        ></div>
                        <div class="country-name">
                            <span>{{ country.country }}</span>
                            <template v-if="bankContentItem.isSkippable()">
                                <a href="" v-if="country.isSkipped" @click.prevent="country.unSkip()">{{ $t("Give Score")}}</a>
                                <a href="" v-else @click.prevent="country.skip()">{{ $t("Skip") }}</a>
                            </template>
                        </div>
                    </div>
                    <div class="banks-card__country--right">
                        <div class="stars-container">
                            <div class="stars-wrapper">
                                <div class="stars-wrapper__title">{{ $t("Score") }}</div>
                                <star-rating
                                    class="justify-content-center"
                                    active-color="#FFAD50"
                                    :star-size="28"
                                    inactive-color="#dcdbff5c"
                                    :rating="country.score"
                                    :round-start-rating="false"
                                    :show-rating="false"
                                    :increment="1"
                                    @update:rating="country.rate(RatingTypesEnum.SCORE, $event)"
                                />
                            </div>
                            <div class="stars-wrapper">
                                <div class="stars-wrapper__title">{{ $t("Importance") }}</div>
                                <star-rating
                                    class="justify-content-center"
                                    active-color="#FFAD50"
                                    :star-size="28"
                                    inactive-color="#F2F2F2"
                                    :rating="country.importance"
                                    :round-start-rating="false"
                                    :show-rating="false"
                                    :increment="1"
                                    @update:rating="country.rate(RatingTypesEnum.IMPORTANCE, $event)"
                                />
                            </div>
                        </div>

                        <el-dropdown v-if="country.comment" trigger="click" placement="bottom-end">
                            <button class="comment-btn btn-comment-exist">
                                <img src="/media/buying/icons/comment-exist-icon.svg" width="23" alt="">
                            </button>
                            <template #dropdown>
                                <el-dropdown-menu class="comment-dropdown">
                                    <el-dropdown-item
                                        @click="$emit('onTriggerCommentAction', {
                                          actionType: ActionsTypeEnum.EDIT,
                                          bankContentItem: bankContentItem,
                                          country,
                                          dataType: 'country'
                                        })"
                                    >
                                        <img src="/media/buying/icons/edit.svg" class="me-2" height="13" width="14"
                                             alt="">
                                        {{ $t("Edit") }}
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                        @click="$emit('onTriggerCommentAction', {
                                          actionType: ActionsTypeEnum.REMOVE,
                                          bankContentItem: bankContentItem,
                                          country,
                                          dataType: 'country'
                                        })"
                                    >
                                        <img src="/media/buying/icons/delete.svg" class="me-2" height="13" width="14"
                                             alt="">
                                        {{ $t("Delete") }}
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>

                        <button
                            v-else
                            class="comment-btn btn-no-comment"
                            @click="$emit('onTriggerCommentAction', {
                              actionType: ActionsTypeEnum.ADD,
                              bankContentItem: bankContentItem,
                              country,
                              dataType: 'country'
                            })">
                            <img src="/media/buying/icons/comment-blue.svg" width="16" alt="">
                        </button>
                    </div>
                </div>
            </div>
        </Transition>
        <div class="banks-card__footer">
            <template v-if="bankContentItem.isSkippable()">
                <a href="" v-if="bankContentItem.isSkipped" @click.prevent="bankContentItem.unSkip()">{{ $t("Give Score") }}</a>
                <a href="" v-else @click.prevent="bankContentItem.skip()">{{ $t("Skip this bank") }}</a>
            </template>

            <button
                class="banks-card__footer--reply"
                :class="{
                    'isSectionOpened': bankContentItem.isSectionOpened,
                    'not-skippable': !bankContentItem.isSkippable()
                }"
                :disabled="bankContentItem.isSkipped || (bankContentItem.isSectionOpened && bankContentItem.isChildTouched)"
                @click="collapse"
            >
                <template v-if="!bankContentItem.isSectionOpened">{{ $t("Reply per country") }}</template>
                <img src="/media/buying/icons/arrow-right.svg" alt="">
            </button>
        </div>
    </div>
</template>

<script>
import StarRating from "vue-star-rating";
import ActionsTypeEnum from "@/store/enums/ActionsTypeEnum";
import RatingTypesEnum from "@/store/enums/RatingTypesEnum";

export default {
    name: "CQScoreImportanceAnswer",
    data() {
        return {
            RatingTypesEnum,
            ActionsTypeEnum,
            showErrorMessage: true
        };
    },
    props: {
        bankContentItem: Object
    },
    emits: ["onTriggerCommentAction"],
    components: {
        StarRating
    },
    methods: {
        collapse() {
            if (this.bankContentItem.isSectionOpened) {
                this.bankContentItem.hideSection();
            } else {
                this.bankContentItem.showSection();
            }
        }
    }
};
</script>

<style lang="scss">
.banks-card {
    background: #FAFBFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    &.is-section-opened {
        background: #FFFFFF;
    }

    &.is-invalid {
        outline: 2px solid rgba(226, 45, 33, 0.3);
        border-color: transparent;
    }

    &.is-valid {
        outline: 2px solid #50CD89;
        border-color: transparent;
    }

    &.is-skipped {
        background: #F0F0F0;

        .banks-card__header {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 9;
            }

            &--bank {
                opacity: 0.3;

                img {
                    filter: grayscale(100%);
                }
            }
        }

        .stars-container {
            opacity: 0.3;
        }

        .comment-btn.btn-no-comment {
            background: #FAFAFA;
            border: 1px solid rgba(67, 91, 244, 0.1);

            img {
                filter: invert(73%) sepia(72%) saturate(1%) hue-rotate(354deg) brightness(92%) contrast(90%);
            }
        }

        .banks-card__footer a {
            font-weight: 700;
            font-size: 16px;
            line-height: 158.02%;
        }
    }

    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.2s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }

    .stars-container {
        display: flex;
        gap: 60px;
        border: 1px solid transparent;

        .stars-wrapper {
            display: flex;
            align-items: center;

            .vue-star-rating {
                gap: 15px;
            }

            &__title {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #666666;
                margin-right: 30px;
            }
        }
    }

    &__country {
        display: flex;
        padding: 17px 12px 12px 25px;
        justify-content: space-between;

        &--bank {
            display: flex;
            align-items: center;

            .bank-icon {
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 1px solid #435BF4;

                &.isValid {
                    background: #E9FEE1;
                    background-image: url("/media/buying/icons/check-icon-green.svg");
                    border: 1px solid #63B775;
                    background-position: center;
                    background-repeat: no-repeat;
                }

                &.isInvalid {
                    background: rgba(226, 45, 33, 0.1);
                    border: 1px solid #E22D21;
                }
            }

            .country-name {
                display: flex;
                flex-direction: column;
                margin-left: 28px;

                span {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 158.02%;
                    color: #2B2B2B;
                    margin-bottom: 8px;
                }

                a {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 158.02%;
                }
            }
        }

        &--right {
            display: flex;
            align-items: center;

            .el-dropdown, .el-dropdown > div {
                height: 100%;
            }

            .stars-container {
                padding: 12px 28px 12px 20px;
                margin-right: 8px;
                background: #FAFBFF;
                border-radius: 8px;
                height: 100%;
            }
        }

        &.isValid {
            background: #F4FFF7;

            .banks-card__country--right .stars-container {
                background: #E9FEE1;
            }
        }

        &.is-skipped {
            background: #FAFAFA;

            .banks-card__country--bank {
                .bank-icon {
                    opacity: 0.5;
                    border: 1px solid #A6A6A6;
                    background: transparent;
                }

                .country-name {
                    span {
                        color: rgba(43, 43, 43, 0.4);
                    }

                    a {
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 158.02%;
                    }
                }
            }

            .banks-card__country--right {
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 9;
                }

                .stars-container {
                    opacity: 0.3;
                    background: #F5F5F5;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                }

                .comment-btn.btn-no-comment {
                    background: #FAFAFA;
                    border: 1px solid rgba(67, 91, 244, 0.1);

                    img {
                        filter: invert(73%) sepia(72%) saturate(1%) hue-rotate(354deg) brightness(92%) contrast(90%);
                    }
                }
            }
        }
    }

    &__header {
        background: #FFFFFF;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px 8px 0 0;
        padding: 13px 12px 12px 16px;
        display: flex;
        flex-direction: column;

        &--content {
            display: flex;
            justify-content: space-between;
        }

        &--bank {
            img {
                height: 38px;
                width: 38px;
                margin-right: 18px;
                border-radius: 50%;
                object-fit: cover;
            }

            span {
                font-weight: 600;
                font-size: 16px;
                line-height: 158.02%;
                color: #2B2B2B;
            }
        }

        &--right {
            display: flex;
            align-items: center;

            .comment-btn {
                margin-left: 28px;
            }

            .comment-btn-blank {
                margin-left: 28px;
                width: 63px;
            }
        }
    }

    &__collapse {
        transition: max-height 0.15s ease-out;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 16px 9px 23px;

        a {
            font-weight: 400;
            font-size: 16px;
            line-height: 158.02%;
            color: #435BF4;
        }

        &--reply {
            font-weight: 700;
            font-size: 12px;
            line-height: 39px;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: 0.1px;
            color: #435BF4;
            cursor: pointer;
            background: transparent;
            border: none;
            padding: 0;

            img {
                margin-left: 11px;
                transform: rotate(90deg);
                width: 12px;
                height: 12px;
                transition: 0.2s ease;
            }

            &.isSectionOpened img {
                transform: rotate(-90deg);
            }

            &:disabled {
                color: #BFBFBF;
                cursor: default;

                img {
                    filter: invert(193%) sepia(1%) saturate(0%) hue-rotate(146deg) brightness(120%) contrast(85%);
                }
            }
        }
        .not-skippable {
            margin-left: auto !important;
        }
    }

    .el-collapse {
        border: none;

        .el-collapse-item {
            display: flex;
            flex-direction: column-reverse;
        }

        .el-collapse-item__header {
            border: none;
            height: 53px;
        }
    }

    .comment-btn {
        width: 63px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;

        &.btn-no-comment {
            background: #FAFBFF;
            border: 1px solid rgba(67, 91, 244, 0.1);
        }

        &.btn-comment-exist {
            background: #E9FEE1;
            border: 1px solid rgba(67, 91, 244, 0.1);

            &:focus {
                border-color: #2BC490;
            }
        }
    }
}

.comment-dropdown {
    .el-dropdown-menu__item {
        font-weight: 400;
        font-size: 16px;
        line-height: 158.02%;
        color: #353535;
        padding: 6px 16px;

        &:last-child {
            img {
                filter: invert(32%) sepia(27%) saturate(5064%) hue-rotate(342deg) brightness(86%) contrast(108%);
            }
        }
    }

    .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
        color: #353535;
    }
}

.item-error-message {
    background: #FFEBEA;
    border-radius: 7px;
    display: flex;
    align-items: center;
    padding: 14px 12px 14px 15px;
    word-break: break-word;

    &__close {
        cursor: pointer;
        transition: .2s ease-in-out;
        margin-left: auto;

        &:hover {
            opacity: 0.5;
        }
    }

    span {
        font-weight: 400;
        font-size: 12px;
        line-height: 19px;
        color: #353535;
    }
}

@media (max-width: 1440px) {
    .banks-card {
        .stars-container {
            margin-right: 15px;
            gap: 20px;

            .stars-wrapper__title {
                margin-right: 15px;
            }

            .stars-wrapper .vue-star-rating {
                gap: 6px;
            }
        }

        .comment-btn {
            width: 40px;

            img {
                width: 14px;
            }
        }

        .comment-btn-blank {
            width: 40px;
        }
    }
}

@media (max-width: 1200px) {
    .banks-card__header--bank {
        span {
            font-size: 14px;
        }

        img {
            margin-right: 10px;
            height: 30px;
            width: 30px;
        }
    }
    .banks-card__country--bank {
        .country-name {
            margin-left: 15px;

            span {
                font-size: 14px;
                line-height: 1;
            }
        }
    }
}

@media (max-width: 900px) {
    .banks-card__header {
        padding: 5px;

        &--bank {
            span {
                font-size: 12px;
            }

            img {
                margin-right: 5px;
                height: 25px;
                width: 25px;
            }
        }

        .comment-btn {
            padding: 5px 0;
        }

        &--right {
            .comment-btn {
                margin-left: 0;
            }
            .comment-btn-blank {
                margin-left: 0;
            }
        }
    }
    .banks-card__footer {
        padding: 5px 8px 5px;

        a {
            font-size: 12px;
        }

        &--reply {
            line-height: 25px;

            img {
                margin-left: 6px;
            }
        }
    }
    .banks-card__country {
        padding: 5px 5px 5px 10px;
    }
    .banks-card .stars-container {
        margin-right: 5px;
        padding: 5px 10px;

        .stars-wrapper {
            .vue-star-rating-star {
                width: 20px;
            }

            .stars-wrapper__title {
                font-size: 11px;
            }
        }
    }
    .banks-card__country--bank {
        .bank-icon {
            width: 12px;
            height: 12px;

            &.isValid {
                background-size: 6px;
            }
        }

        .country-name {
            margin-left: 10px;

            span {
                font-size: 12px;
                line-height: 1;
                margin-bottom: 2px;
            }
        }
    }
    .comment-dropdown .el-dropdown-menu__item {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .banks-card__header {

        &--content {
            flex-direction: column;
        }

        &--bank {
            width: 100%;
        }

        &--right {
            width: 100%;
            justify-content: space-between;

            .stars-container {
                padding: 0;
            }

            .comment-btn-blank {
                display: none;
            }
        }
    }
    .banks-card {
        &__country {
            flex-direction: column;
            border-bottom: 1px solid rgb(0 0 0 / 4%);

            &--bank {
                .country-name {
                    flex-direction: revert;
                    align-items: center;
                    width: calc(100% - 22px);
                    justify-content: space-between;
                    padding-right: 10px;

                    span {
                        margin-right: 5px;
                    }
                }
            }

            &--right {
                justify-content: space-between;
            }

            .comment-btn {
                padding: 5px 0;
            }
        }

        .stars-container .stars-wrapper .vue-star-rating {
            gap: 3px;
        }
    }
    .banks-card__header--right .comment-btn {
        margin-left: 0;
    }
}

@media (max-width: 500px) {
    .banks-card {
        .stars-container {
            flex-direction: column;
            gap: 2px;

            .stars-wrapper {
                justify-content: space-between;
            }
        }
    }
}
</style>
