<template>
    <div
        class="banks-card multiple-choice"
        :class="{
            'is-section-opened': bankContentItem.isSectionOpened,
            'is-skipped': bankContentItem.isSkipped,
            'is-invalid': bankContentItem.isContentValid === false,
            'is-valid': bankContentItem.isContentValid === true,
        }"
    >
        <div class="banks-card__header">
            <div class="banks-card__header--bank">
                <img :src="bankContentItem.bank.icon_path" alt="">
                <span>{{ bankContentItem.bank.name }}</span>
            </div>

            <div class="banks-card__header--right">
                <a href="" v-if="bankContentItem.isSkipped" @click.prevent="bankContentItem.unSkip()">{{ $t('Reply for this bank') }}</a>
                <a href="" v-if="!bankContentItem.isSkipped && bankContentItem.isSkippable()" @click.prevent="bankContentItem.skip()">{{ $t('Skip this bank') }}</a>
            </div>
        </div>

        <div class="multiple-choice__options" v-if="isMultipleSelect">
            <el-checkbox-group
                v-model="bankContentItem.answers"
                @change="bankContentItem.onAnswer()"
                :disabled="bankContentItem.isSkipped"
            >
                <template
                    v-for="(option, index) in bankContentItem.options"
                    :key="index"
                >
                    <el-checkbox :label="option.id">{{ option.label }}</el-checkbox>
                </template>
            </el-checkbox-group>
        </div>

        <div class="multiple-choice__options" v-else>
            <el-radio-group
                v-model="bankContentItem.answer"
                @change="bankContentItem.onAnswer()"
                :disabled="bankContentItem.isSkipped"
            >
                <template
                    v-for="(option, index) in bankContentItem.options"
                    :key="index"
                >
                    <el-radio :label="option.id">{{ option.label }}</el-radio>
                </template>
            </el-radio-group>
        </div>
    </div>
</template>

<script>
export default {
    name: "CQMultipleChoiceAnswer",
    props: {
        bankContentItem: Object,
        isMultipleSelect: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
.multiple-choice {
    overflow: hidden;
    margin-bottom: 20px;

    .banks-card__header {
        border-bottom: 0;
    }

    .banks-card__header--right {
        margin-top: 10px;
        a {
            font-weight: 400;
            font-size: 12px;
            line-height: 158.02%;
            color: #435BF4;
        }
    }

    &__options {
        background: #fff;

        .el-checkbox__label, .el-radio__label {
            color: #666;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .el-radio-group, .el-checkbox-group {
            display: flex;
            flex-direction: column;
            padding-bottom: 10px;

            .el-radio, .el-checkbox {
                padding-left: 16px;
                margin-right: 0;
                padding-right: 16px;

                &:hover {
                    background: rgba(67, 91, 244, 0.05);
                }
            }
        }
    }

    &.is-skipped {
        .multiple-choice__options {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: #ffffffb5;
            }
        }

        .banks-card__header:after {
            display: none;
        }

        .banks-card__header--right a {
            font-weight: 700;
        }
    }
}
</style>
