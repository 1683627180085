import { diagLog, diagLogClass } from "@/core/helpers/GlobalHelper";
import { UserModel } from "@/store/models/UserModel";
import { BusinessBankModel } from "@/store/models/BusinessBankModel";
import RatingTypesEnum from "@/store/enums/RatingTypesEnum";
import { v4 as uuidv4 } from "uuid";
import {
    Constants,
    CUSTOM_QUESTION_TEXT_ONLY_ANSWER_MAX_LENGTH,
    CUSTOM_QUESTION_TEXT_ONLY_ANSWER_MIN_LENGTH
} from "@/core/config/constants";
import { FeedbackSessionQuestionsTypeEnum } from "@/store/enums/FeedbackSessionQuestionsTypeEnum";

export class ReplyFeedbackDataContainer {
    id: number;
    productAreas: [];
    phases: [];
    creator: UserModel;
    questions: RFDCQuestionItem[];
    banks: BusinessBankModel[];
    activeQuestionIndex: number;
    validationInfo: any;
    isCompleted: boolean = false;
    submittingLoading: boolean = false;
    completedStats: CompletedStatsModel;
    surveyDataId: any;
    isSocGen: boolean = false;

    constructor(obj: any) {
        if (obj.start_question_indx === obj.questions.length) {
            this.isCompleted = true;
        }
        this.id = obj.id;
        this.activeQuestionIndex = obj.start_question_indx;
        this.productAreas = obj.product_areas;
        this.phases = obj.phases;
        this.creator = new UserModel(obj.creator);
        this.banks = obj.banks_data.map(bk => new BusinessBankModel(bk));
        this.questions = obj.questions.map((qn, index) => new RFDCQuestionItem(qn, this.banks));
        this.completedStats = new CompletedStatsModel(null);
        this.isSocGen = obj.isSocGen ? obj.isSocGen : false;
        diagLogClass(this, obj)
        diagLogClass(this, 'Active Question', this.getActiveQuestion())
    }

    setSurveyDataId(id: any) {
        this.surveyDataId = id;
    }

    getActiveQuestion() {
        return this.questions[this.activeQuestionIndex];
    }

    validateActiveQuestion() {
        return new Promise((resolve, reject) => {
            this.validationInfo = this.getActiveQuestion().validate();
            diagLog(this, this.validationInfo);
            if (this.validationInfo.isQuestionValid) {
                resolve(true);
            } else {
                reject(this.validationInfo);
            }
        });
    }

    getAnswers() {
        let activeQuestion = this.getActiveQuestion();
        let { questionAnswers, isAllBanksSkipped } = activeQuestion.getAnswers();
        return {
            isAllBanksSkipped,
            payload: {
                answers: questionAnswers,
                question_id: activeQuestion.id,
                is_custom: this.getActiveQuestion().isCustom,
                survey_id: this.id
            }
        };
    }

    nextQuestion() {
        this.activeQuestionIndex++;
    }

    skipQuestion() {
        this.getActiveQuestion().skip();
    }

    setCompleted() {
        this.isCompleted = true;
    }

    setCompletedStats(data) {
        this.completedStats = new CompletedStatsModel(data ? data : null);
    }

    setSubmittingLoadingStatus(value: boolean) {
        this.submittingLoading = value;
    }
}

export class RFDCQuestionItemReplyContentContainer {
    constructor() {}

    isValid() {
        console.error('isValid not defined');
    }
    validate() {
        console.error('validate not defined');
    }
    getAnswers() {
        console.error('getAnswers not defined');
    }
    getItemsLength() {
        console.error('getItemsLength not defined');
    }
}
// Score Importance Type Start
export class RFDCQuestionItemReplyScoreImportanceContentContainer extends RFDCQuestionItemReplyContentContainer {
    items = [] as RFDCQuestionScoreImportanceReplyBankContentItem[];

    constructor(question: RFDCQuestionItem, banks: BusinessBankModel[]) {
        super();

        banks.forEach(bank => {
            this.items.push(new RFDCQuestionScoreImportanceReplyBankContentItem(bank, question));
        });
    }

    isValid() {
        return this.items.every((bank: RFDCQuestionScoreImportanceReplyBankContentItem) => bank.isValid());
    }

    validate() {
        let invalidBankIndex: number | null = null;
        let invalidBankItem: RFDCQuestionScoreImportanceReplyBankContentItem | null = null;
        let isQuestionValid: boolean = true;

        this.items.forEach((bank: RFDCQuestionScoreImportanceReplyBankContentItem, index) => {
            let { isValid, item } = bank.validate();
            if (!isValid && invalidBankIndex === null) {
                invalidBankIndex = index;
                invalidBankItem = item;
                isQuestionValid = false;
            }
        });

        return { invalidBankIndex, bankItem: invalidBankItem, isQuestionValid };
    }

    getAnswers(): any {
        let questionAnswers = [] as any[];
        let isAllBanksSkipped = true;

        this.items.forEach((bankContentItem: RFDCQuestionScoreImportanceReplyBankContentItem, index) => {
            if (!bankContentItem.isSkipped) {
                isAllBanksSkipped = false;
                let { bankCountriesAnswers, isAllCountriesSkipped } = bankContentItem.getAnswers();
                if (isAllCountriesSkipped) {
                    isAllBanksSkipped = true;
                } else {
                    questionAnswers.push({
                        bank_id: bankContentItem.bank.id,
                        stats: bankCountriesAnswers
                    });
                }
            }
        });

        return { questionAnswers, isAllBanksSkipped };
    }

    getItemsLength() {
        return this.items.length;
    }
}
export class RFDCQuestionScoreImportanceReplyBankContentItem {
    isSkipped: boolean = false;
    bank: BusinessBankModel;
    bankCountries: RFDCQuestionScoreImportanceReplyCountryContentItem[] = [];
    isSectionOpened: boolean = true;
    questionContainer: RFDCQuestionItem;
    score: number = 0;
    importance: number = 0;
    isChildTouched: boolean = false;
    isContentValid: boolean | null = null;
    comment: string | null = null;

    constructor(bank: BusinessBankModel, questionContainer: RFDCQuestionItem) {
        this.questionContainer = questionContainer;
        this.bank = bank;

        bank.countries.forEach(country => {
            this.bankCountries.push(new RFDCQuestionScoreImportanceReplyCountryContentItem(country, this));
        });
    }

    isSkippable() {
        return !this.questionContainer.isRequired;
    }

    isCommentAllowed() {
        return this.bankCountries.length === 1;
    }

    isGroupRatingVisible() {
        return this.bankCountries.length > 1;
    }

    setChildTouched() {
        this.isChildTouched = true;
    }

    skip() {
        this.isSkipped = true;
        if (!this.isChildTouched) {
            this.isSectionOpened = false;
        }
        this.bankCountries.forEach((country: RFDCQuestionScoreImportanceReplyCountryContentItem) => {
            country.setSkipped();
        });
    }

    unSkip() {
        this.isSkipped = false;
        this.bankCountries.forEach((country: RFDCQuestionScoreImportanceReplyCountryContentItem) => {
            country.setUnSkipped();
        });
    }

    rate(type: RatingTypesEnum, value: number) {
        this.isSkipped = false;
        this[type] = value;

        this.bankCountries.forEach((country: RFDCQuestionScoreImportanceReplyCountryContentItem) => {
            country.setRated(type, value);
        });

        if (this.questionContainer.isValidated) {
            this.validate();
        }
    }

    addComment(comment: string) {
        this.comment = comment;
        this.bankCountries.forEach((country: RFDCQuestionScoreImportanceReplyCountryContentItem) => {
            country.setCommented(comment);
        });
    }

    deleteComment() {
        this.comment = null;
        this.bankCountries.forEach((country: RFDCQuestionScoreImportanceReplyCountryContentItem) => {
            country.setUnCommented();
        });
    }

    showSection() {
        this.isSectionOpened = true;
    }

    hideSection() {
        if (!this.isChildTouched) this.isSectionOpened = false;
    }

    validate() {
        this.isContentValid = this.isValid();

        return { isValid: this.isContentValid, item: this };
    }

    isValid() {
        return this.isSkipped || this.bankCountries.every((country: RFDCQuestionScoreImportanceReplyCountryContentItem) => country.isValid());
    }

    getAnswers(): any {
        let bankCountriesAnswers = [] as any[];
        let isAllCountriesSkipped = true;
        this.bankCountries.forEach((cnt: RFDCQuestionScoreImportanceReplyCountryContentItem, index) => {
            if (!cnt.isSkipped) {
                isAllCountriesSkipped = false;
                bankCountriesAnswers.push(cnt.getAnswers());
            }
        });

        return { bankCountriesAnswers, isAllCountriesSkipped };
    }
}
export class RFDCQuestionScoreImportanceReplyCountryContentItem {
    id: number;
    country: string;
    score: number = 0;
    importance: number = 0;
    comment: string | null = null;
    isSkipped: boolean = false;
    bankContainer: RFDCQuestionScoreImportanceReplyBankContentItem;
    isTouched: boolean = false;

    constructor(country: string, bankContainer: RFDCQuestionScoreImportanceReplyBankContentItem) {
        this.id = uuidv4();
        this.country = country;
        this.bankContainer = bankContainer;
    }

    skip() {
        this.setTouched();
        this.setSkipped();
        this.bankContainer.setChildTouched();
    }

    unSkip() {
        this.setTouched();
        this.setUnSkipped();
        this.bankContainer.setChildTouched();
    }

    setSkipped() {
        this.setTouched();
        this.isSkipped = true;
    }

    setUnSkipped() {
        this.setTouched();
        this.isSkipped = false;
    }

    addComment(comment: string) {
        this.setTouched();
        this.comment = comment;
        this.bankContainer.setChildTouched();
    }

    setCommented(comment: string) {
        this.setTouched();
        this.comment = comment;
    }

    deleteComment() {
        this.setTouched();
        this.comment = null;
        this.bankContainer.setChildTouched();
    }

    setUnCommented() {
        this.setTouched();
        this.comment = null;
    }

    rate(type: RatingTypesEnum, value: number) {
        this.setTouched();
        this.setRated(type, value);
        this.bankContainer.setChildTouched();
        if (this.bankContainer.questionContainer.isValidated) {
            this.bankContainer.validate();
        }
    }

    setRated(type: RatingTypesEnum, value: number) {
        this.setTouched();
        this.isSkipped = false;
        this[type] = value;
    }

    setTouched() {
        this.isTouched = true;
    }

    isValid() {
        return this.isSkipped || (this.score && this.importance);
    }

    getAnswers() {
        return {
            score: this.score,
            importance: this.importance,
            country: this.country,
            is_skipped: this.isSkipped,
            comment: this.comment
        };
    }
}
// Score Importance Type End

// Text Only Type Start
export class RFDCQuestionItemTextOnlyReplyContentContainer extends RFDCQuestionItemReplyContentContainer {
    items = [] as RFDCQuestionTextOnlyReplyBankContentItem[];

    constructor(question: RFDCQuestionItem, banks: BusinessBankModel[]) {
        super();

        banks.forEach(bank => {
            this.items.push(new RFDCQuestionTextOnlyReplyBankContentItem(bank, question));
        });
    }

    isValid() {
        // return this.items.every((bank: RFDCQuestionScoreImportanceReplyBankContentItem) => bank.isValid());
    }

    validate() {
        let invalidBankIndex: number | null = null;
        let invalidBankItem: RFDCQuestionTextOnlyReplyBankContentItem | null = null;
        let isQuestionValid: boolean = true;

        this.items.forEach((bank: RFDCQuestionTextOnlyReplyBankContentItem, index) => {
            let { isValid, item } = bank.validate();

            if (!isValid && invalidBankIndex === null) {
                invalidBankIndex = index;
                invalidBankItem = item;
                isQuestionValid = false;
            }
        });

        return { invalidBankIndex, bankItem: invalidBankItem, isQuestionValid };
    }

    getAnswers(): any {
        let questionAnswers = [] as any[];
        let isAllBanksSkipped = true;

        this.items.forEach((bankContentItem: RFDCQuestionTextOnlyReplyBankContentItem, index) => {
            let {bankId, isSkipped, answer} = bankContentItem.getAnswers();
            if (!isSkipped) {
                isAllBanksSkipped = false;
                questionAnswers.push({bank_id: bankId, isSkipped, answer_text: answer});
            }
        });

        return { questionAnswers, isAllBanksSkipped };
    }

    getItemsLength() {
        return this.items.length;
    }
}
export class RFDCQuestionTextOnlyReplyBankContentItem {
    isSkipped: boolean = false;
    bank: BusinessBankModel;
    questionContainer: RFDCQuestionItem;
    isContentValid: boolean | null = null;
    answer: string = '';

    constructor(bank: BusinessBankModel, questionContainer: RFDCQuestionItem) {
        this.questionContainer = questionContainer;
        this.bank = bank;
    }

    isSkippable() {
        return !this.questionContainer.isRequired;
    }

    skip() {
        this.isSkipped = true;

        if (this.questionContainer.isValidated) {
            this.validate();
        }
    }

    unSkip() {
        this.isSkipped = false;

        if (this.questionContainer.isValidated) {
            this.validate();
        }
    }

    onAnswer() {
        if (this.questionContainer.isValidated) {
            this.validate();
        }
    }

    validate() {
        this.isContentValid = this.isValid();

        return { isValid: this.isContentValid, item: this };
    }

    isValid() {
        return this.isSkipped ||
            !!(this.answer && this.answer.length >= CUSTOM_QUESTION_TEXT_ONLY_ANSWER_MIN_LENGTH && this.answer.length <= CUSTOM_QUESTION_TEXT_ONLY_ANSWER_MAX_LENGTH);
    }

    getAnswers(): any {
        return { bankId: this.bank.id, isSkipped: this.isSkipped, answer: this.answer };
    }
}
// Text Only Type End

// Multiple Choice Type Start
export class RFDCQuestionItemMultipleChoiceReplyContentContainer extends RFDCQuestionItemReplyContentContainer {
    items = [] as RFDCQuestionItemMultipleChoiceReplyBankContentItem[];

    constructor(question: RFDCQuestionItem, banks: BusinessBankModel[]) {
        super();

        banks.forEach(bank => {
            this.items.push(new RFDCQuestionItemMultipleChoiceReplyBankContentItem(bank, question));
        });
    }

    isValid() {
        return this.items.every((bank: RFDCQuestionItemMultipleChoiceReplyBankContentItem) => bank.isValid());
    }

    validate() {
        let invalidBankIndex: number | null = null;
        let invalidBankItem: RFDCQuestionItemMultipleChoiceReplyBankContentItem | null = null;
        let isQuestionValid: boolean = true;

        this.items.forEach((bank: RFDCQuestionItemMultipleChoiceReplyBankContentItem, index) => {
            let { isValid, item } = bank.validate();

            if (!isValid && invalidBankIndex === null) {
                invalidBankIndex = index;
                invalidBankItem = item;
                isQuestionValid = false;
            }
        });

        return { invalidBankIndex, bankItem: invalidBankItem, isQuestionValid };
    }

    getAnswers(): any {
        let questionAnswers = [] as any[];
        let isAllBanksSkipped = true;

        this.items.forEach((bankContentItem: RFDCQuestionItemMultipleChoiceReplyBankContentItem, index) => {
            let {bankId, isSkipped, answer} = bankContentItem.getAnswers();
            if (!isSkipped) {
                isAllBanksSkipped = false;
                questionAnswers.push({bank_id: bankId, isSkipped, option_ids: answer});
            }
        });

        return { questionAnswers, isAllBanksSkipped };
    }

    getItemsLength() {
        return this.items.length;
    }
}
export class RFDCQuestionItemMultipleChoiceReplyBankContentItem {
    isSkipped: boolean = false;
    bank: BusinessBankModel;
    questionContainer: RFDCQuestionItem;
    isContentValid: boolean | null = null;
    answer?: string;
    answers?: string[];
    options: ColumnRowOptionModel[];

    constructor(bank: BusinessBankModel, questionContainer: RFDCQuestionItem) {
        this.questionContainer = questionContainer;
        this.bank = bank;
        this.options = this.questionContainer.options!;

        if (this.questionContainer.isMultipleSelect) {
            this.answers = [];
        } else {
            this.answer = '';
        }
    }

    isSkippable() {
        return !this.questionContainer.isRequired;
    }

    skip() {
        this.isSkipped = true;

        if (this.questionContainer.isValidated) {
            this.validate();
        }
    }

    unSkip() {
        this.isSkipped = false;

        if (this.questionContainer.isValidated) {
            this.validate();
        }
    }

    onAnswer() {
        if (this.questionContainer.isValidated) {
            this.validate();
        }
    }

    validate() {
        this.isContentValid = this.isValid();

        return { isValid: this.isContentValid, item: this };
    }

    isValid() {
        return this.isSkipped ||
            !!(!this.questionContainer.isMultipleSelect && this.answer) ||
            !!(this.questionContainer.isMultipleSelect && this.answers && this.answers.length > 0);
    }

    getAnswers(): any {
        return { bankId: this.bank.id, isSkipped: this.isSkipped, answer: this.questionContainer.isMultipleSelect ? this.answers : [this.answer] };
    }
}
// Multiple Choice Type End


// Multiple Choice Grid Type Start
export class RFDCQuestionItemMultipleChoiceGridReplyContentContainer extends RFDCQuestionItemReplyContentContainer {
    items = [] as RFDCQuestionItemMultipleChoiceGridReplyBankContentItem[];

    constructor(question: RFDCQuestionItem, banks: BusinessBankModel[]) {
        super();

        banks.forEach(bank => {
            this.items.push(new RFDCQuestionItemMultipleChoiceGridReplyBankContentItem(bank, question));
        });
    }

    isValid() {
        return this.items.every((bank: RFDCQuestionItemMultipleChoiceGridReplyBankContentItem) => bank.isValid());
    }

    validate() {
        let invalidBankIndex: number | null = null;
        let invalidBankItem: RFDCQuestionItemMultipleChoiceGridReplyBankContentItem | null = null;
        let isQuestionValid: boolean = true;

        this.items.forEach((bank: RFDCQuestionItemMultipleChoiceGridReplyBankContentItem, index) => {
            let { isValid, item } = bank.validate();

            if (!isValid && invalidBankIndex === null) {
                invalidBankIndex = index;
                invalidBankItem = item;
                isQuestionValid = false;
            }
        });

        return { invalidBankIndex, bankItem: invalidBankItem, isQuestionValid };
    }

    getAnswers(): any {
        let questionAnswers = [] as any[];
        let isAllBanksSkipped = true;

        this.items.forEach((bankContentItem: RFDCQuestionItemMultipleChoiceGridReplyBankContentItem, index) => {
            let {bankId, isSkipped, answer} = bankContentItem.getAnswers();
            if (!isSkipped) {
                isAllBanksSkipped = false;
                questionAnswers.push({bank_id: bankId, isSkipped, answer});
            }
        });

        return { questionAnswers, isAllBanksSkipped };
    }

    getItemsLength() {
        return this.items.length;
    }
}
export class RFDCQuestionItemMultipleChoiceGridReplyBankContentItem {
    isSkipped: boolean = false;
    bank: BusinessBankModel;
    questionContainer: RFDCQuestionItem;
    isContentValid: boolean | null = null;
    answers: string[] = [];
    options: ColumnRowOptionModel[] = [];
    rows: ColumnRowOptionModel[] = [];
    rowItems: GridRowItem[] = [];

    constructor(bank: BusinessBankModel, questionContainer: RFDCQuestionItem) {
        this.questionContainer = questionContainer;
        this.bank = bank;

        this.options = questionContainer.columns!;
        this.rows = questionContainer.rows!;

        this.rows.forEach((rw: ColumnRowOptionModel) => {
            this.rowItems.push(new GridRowItem(rw, this.options));
        })
    }

    isSkippable() {
        return !this.questionContainer.isRequired;
    }

    skip() {
        this.isSkipped = true;

        if (this.questionContainer.isValidated) {
            this.validate();
        }
    }

    unSkip() {
        this.isSkipped = false;

        if (this.questionContainer.isValidated) {
            this.validate();
        }
    }

    onAnswer() {
        if (this.questionContainer.isValidated) {
            this.validate();
        }
    }

    validate() {
        this.isContentValid = this.isValid();

        return { isValid: this.isContentValid, item: this };
    }

    isValid() {
        return this.isSkipped || this.rowItems.every((rwI: GridRowItem) => rwI.isValid());
    }

    getAnswers(): any {
        let answers: any[] = [];
        this.rowItems.forEach((rwI: GridRowItem) => {
            if (rwI.isValid() && rwI.answer) {
                answers.push(rwI.getAnswers())
            }
        });
        return { bankId: this.bank.id, isSkipped: this.isSkipped, answer: answers };
    }
}

export class GridRowItem {
    row: ColumnRowOptionModel;
    options: ColumnRowOptionModel[];
    answer: number | null = null;

    constructor(row: ColumnRowOptionModel, options: ColumnRowOptionModel[]) {
        this.row = row;
        this.options = options;
    }

    isValid() {
        return !!this.answer;
    }

    getAnswers() {
        return {
            row_id: this.row.id,
            column_id: this.answer
        }
    }
}
// Multiple Choice Grid Type Start

export class RFDCQuestionItem {
    id: number;
    questionId: number;
    question: string;
    priority: number;
    productArea: string;
    phase: string;
    replyContentContainer: RFDCQuestionItemReplyContentContainer;
    isValidated: boolean = false;
    type: FeedbackSessionQuestionsTypeEnum = FeedbackSessionQuestionsTypeEnum.SCORE_IMPORTANCE;
    isCustom: boolean;
    isRequired: boolean;
    isMultipleSelect: boolean;
    options?: ColumnRowOptionModel[] = [];
    rows?: ColumnRowOptionModel[] = [];
    columns?: ColumnRowOptionModel[] = [];

    constructor(obj: any, banks: BusinessBankModel[]) {
        this.id = obj.id;
        this.questionId = obj.question_id;
        this.question = obj.question;
        this.priority = obj.priority;
        this.productArea = obj.product_area;
        this.phase = obj.phase ?? "Custom Question";
        this.isCustom = obj.is_custom;
        this.type = obj.type;
        this.isRequired = obj.is_required;
        this.isMultipleSelect = obj.is_multi_select;

        if (obj.options) this.options = obj.options.map(opt => new ColumnRowOptionModel(opt));
        if (obj.rows) this.rows = obj.rows.map(rw => new ColumnRowOptionModel(rw));
        if (obj.columns) this.columns = obj.columns.map(opt => new ColumnRowOptionModel(opt)).sort((opt1, opt2) => opt1.id - opt2.id);

        this.replyContentContainer = this.constructReplyContentContainer(banks);
    }

    constructReplyContentContainer(banks: BusinessBankModel[]) {
        switch (this.type) {
            case FeedbackSessionQuestionsTypeEnum.SCORE_IMPORTANCE:
                return new RFDCQuestionItemReplyScoreImportanceContentContainer(this, banks);
            case FeedbackSessionQuestionsTypeEnum.TEXT_ONLY:
                return new RFDCQuestionItemTextOnlyReplyContentContainer(this, banks);
            case FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE:
                return new RFDCQuestionItemMultipleChoiceReplyContentContainer(this, banks);
            case FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE_GRID:
                return new RFDCQuestionItemMultipleChoiceGridReplyContentContainer(this, banks);
            default:
                return new RFDCQuestionItemReplyScoreImportanceContentContainer(this, banks);
        }
    }

    skip() {
        diagLog("skipQuestion", "API call done from component");
    }

    isValid() {
        return this.replyContentContainer.isValid();
    }

    setValidated() {
        this.isValidated = true;
    }

    validate() {
        this.setValidated();
        return this.replyContentContainer.validate();
    }

    getAnswers(): any {
        return this.replyContentContainer.getAnswers();
    }
}



class CompletedStatsModel {
    countries: any[] = [];
    product_areas: any[] = [];
    banks: any[] = [];

    constructor(obj) {
        if (obj) {
            this.countries = this.setStatsData(obj.countries);
            this.product_areas = this.setStatsData(obj.product_areas);
            this.banks = this.setStatsData(obj.banks, true);
        }
    }

    setStatsData(data: any[], showIcon: boolean = false) {
        const convertedData: any = [];
        for (const [key, value] of Object.entries(data)) {
            convertedData.push(new CompletedStatsItemModel({
                name: key,
                ...value,
                showIcon
            }));
        }
        return convertedData;
    }
}

class CompletedStatsItemModel {
    name: string;
    gap: string;
    importance: string;
    score: string;
    icon_path?: string;

    constructor(obj) {
        this.name = obj.name;
        this.gap = obj.gap;
        this.importance = obj.importance;
        this.score = obj.score;
        if (obj.showIcon) {
            this.icon_path = obj.icon_path ? obj.icon_path : Constants.BANK_TMP_LOGO;
        }
    }
}

class ColumnRowOptionModel {
    id: number;
    label: string;

    constructor(obj: any) {
        this.id = obj.id;
        this.label = obj.label;
    }
}
