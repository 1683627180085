<template>
    <div
        class="banks-card card-text-only"
        :class="{
            'is-section-opened': bankContentItem.isSectionOpened,
            'is-skipped': bankContentItem.isSkipped,
            'is-invalid': bankContentItem.isContentValid === false,
            'is-valid': bankContentItem.isContentValid === true,
        }"
    >
        <div class="banks-card__header">
            <div class="banks-card__header--bank">
                <img :src="bankContentItem.bank.icon_path" alt="">
                <span>{{ bankContentItem.bank.name }}</span>
            </div>

            <div class="banks-card__header--right">
                <a href="" v-if="bankContentItem.isSkipped" @click.prevent="bankContentItem.unSkip()">{{ $t('Reply for this bank') }}</a>
                <a href="" v-if="!bankContentItem.isSkipped && bankContentItem.isSkippable()" @click.prevent="bankContentItem.skip()">{{ $t('Skip this bank') }}</a>
            </div>
        </div>

        <div class="card-text-only__input" :class="{'is-invalid': false}">
            <textarea
                v-model="bankContentItem.answer"
                @input="bankContentItem.onAnswer()"
                rows="1"
                :disabled="bankContentItem.isSkipped"
                :placeholder="$t('Type your answer here')"
            ></textarea>
            <div class="input-info">{{ bankContentItem.answer.length }}/300 {{ $t('Characters') }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CQTextOnlyAnswer",
    props: {
        bankContentItem: Object
    },
}
</script>

<style lang="scss">
.card-text-only {
    overflow: hidden;

    .banks-card__header {
        border-bottom: 0;
    }

    .banks-card__header--right {
        margin-top: 10px;
        a {
            font-weight: 400;
            font-size: 12px;
            line-height: 158.02%;
            color: #435BF4;
        }
    }

    &__input {
        background: #fff;
        padding: 12px 16px 27px 16px;
        display: flex;
        flex-direction: column;

        textarea {
            background: #FBFCFF;
            box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.1);
            border-radius: 7px;
            width: 100%;
            padding: 20px 15px 20px 26px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            outline: none;
            transition: .2s ease-in-out;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #272727;
            margin-bottom: 8px;
            &:focus {
                border-color: #435BF4;
            }
        }

        .input-info {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #666666;
            padding-left: 5px;
        }

        &.is-invalid {
            textarea {
                border: 1px solid #E22D21;
            }
            .input-info {
                color: #E22D21;
            }
        }
    }

    &.is-skipped {
        .card-text-only__input {
            position: relative;
            textarea {
                border: 1px solid #a5a5a5;
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: #ffffffb5;
            }
        }

        .banks-card__header:after {
            display: none;
        }

        .banks-card__header--right a {
            font-weight: 700;
        }
    }
}
</style>
