<template>
  <div class="involved">
    <div class="involved__title">
      {{ $t('Product Area Involved') }}
      <span>{{ productAreas.length }}</span>
    </div>
    <div class="involved__card">
      <div
        v-for="(prArea, index) in productAreas"
        :key="index"
        class="involved__card--item"
        :class="{
          'item-done': index < getActiveIndex,
          'item-progress': index === getActiveIndex
        }"
      >
        <div class="card-item-icon"></div>
        <span>{{ prArea }}</span>
      </div>
    </div>
    <button class="involved__card--btn" @click="$emit('popupClose')">
      {{ $t('Leave Feedback Session') }}
    </button>
    <p>{{ $t('You can always resume the feedback session before the expiry') }}</p>
  </div>
</template>

<script>
export default {
  name: "ProductAreaInvolved",
  props: {
    productAreas: Array,
    activeProductArea: String
  },
  emits: ['popupClose'],
  computed: {
    getActiveIndex() {
      return this.productAreas.indexOf(this.activeProductArea);
    },
  }
}
</script>

<style scoped lang="scss">
.involved {
  &__title {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #666666;
    display: flex;
    align-items: center;
    padding-left: 10px;
    min-height: 35px;
    span {
      background: #ECECEC;
      border-radius: 4px;
      padding: 2px 10px;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      margin-left: 10px;
    }
  }
  &__card {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 18px 0;
    margin-bottom: 12px;
    &--item {
      padding-left: 26px;
      opacity: 0.5;
      display: flex;
      align-items: center;
      min-height: 34px;
      padding-right: 14px;
      word-break: break-word;
      .card-item-icon {
        width: 18px;
        height: 18px;
        background: #EEEEEE;
        border-radius: 50%;
        margin-right: 10px;
      }
      span {
        font-weight: 600;
        font-size: 12px;
        line-height: 158.02%;
        color: #2B2B2B;
        width: calc(100% - 28px);
      }
      &:not(:last-child) {
        margin-bottom: 29px;
      }

      &.item-done, &.item-progress {
        opacity: 1;
      }

      &.item-done {
        .card-item-icon {
          background: #E9FEE1;
          border: 1px solid #63B775;
          background-image: url("/media/buying/icons/check-icon-green.svg");
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      &.item-progress {
        .card-item-icon {
          background: rgba(67, 91, 244, 0.1);
          border: 1px solid #435BF4;
        }
      }
    }
    &--btn {
      background: #FFEBEA;
      border: none;
      width: 100%;
      border-radius: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 158.02%;
      text-align: center;
      color: #CA0F0F;
      padding: 10px;
      margin-bottom: 12px;
      transition: .2s ease-in-out;
      &:hover {
        background: #ffe5e3;
      }
    }
  }
  > p {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #666666;
  }
}
@media (max-width: 1200px) {
  .involved__card--item {
    padding-left: 16px;
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }
  .involved__card--btn {
    font-size: 13px;
  }
}
</style>
