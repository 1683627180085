<template>
    <div class="question-details">
        <div class="question-details__title">
            {{ $t('Question Details') }}
        </div>
        <div class="question-details__card">
            <div class="question-details__card--header">
                <div class="header-question-label">
                    <h5>{{ $t('Question') }}</h5>
                    <div v-if="questionDetails.isCustom" class="custom-question-label">
                        <inline-svg src="/media/buying/icons/info-icon-gray.svg" width="15px" height="15px" />
                        {{ $t('Custom Question') }}
                    </div>
                </div>
                <div class="header-id">
                    {{ $t('ID') }} <span>{{ questionDetails.id }}</span>
                </div>
            </div>
            <div class="question-details__card--text">
                {{ questionDetails.question }}
            </div>
            <div class="question-details__card--footer">
                <div class="footer-grid">
                    <div class="footer-grid__item">
                        <span>{{ $t('Phase') }}</span>
                        <h4>{{ questionDetails.phase }}</h4>
                    </div>
                    <div class="footer-grid__item">
                        <span>{{ $t('Product Area') }}</span>
                        <h4>{{ questionDetails.productArea }}</h4>
                    </div>
                    <div
                        v-if="questionDetails.isCustom"
                        class="footer-grid__item"
                    >
                        <span>{{ $t('Question Type') }}</span>
                        <h4
                            v-if="FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE === questionDetails.type"
                        >{{ questionDetails.isMultipleSelect ? $t('Multiple Choice') : $t('Single Choice') }}</h4>
                        <h4 v-else>{{ $t(questionDetails.type) }}</h4>
                    </div>
                </div>
                <div class="footer-paging">
                    {{
                        $t('NUM1 out of NUM2', {
                            num1: feedbackDataContainer.activeQuestionIndex + 1,
                            num2: feedbackDataContainer.questions.length
                        })
                    }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FeedbackSessionQuestionsTypeEnum } from "@/store/enums/FeedbackSessionQuestionsTypeEnum";

export default {
    name: "QuestionDetails",
    props: {
        questionDetails: Object,
        feedbackDataContainer: Object
    },
    data() {
        return {
            FeedbackSessionQuestionsTypeEnum
        }
    }
}
</script>

<style lang="scss">
.question-details {
    &__title {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #666666;
        min-height: 35px;
        display: flex;
        align-items: center;
    }

    &__card {
        background: #FFFFFF;
        box-shadow: 0px 9px 29px rgba(0, 0, 0, 0.09);
        border-radius: 8px;
        padding: 22px 13px 14px 16px;

        &--header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 18px;

            h5 {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #666666;
                margin-bottom: 0;
            }

            .header-id {
                font-weight: 400;
                font-size: 12px;
                line-height: 158.02%;
                color: #969696;

                span {
                    color: #0A0A0A;
                    font-weight: 600;
                }
            }

            .header-question-label {
                display: flex;
                align-items: center;
                min-height: 32px;
            }
        }

        &--text {
            font-weight: 500;
            font-size: 28px;
            line-height: 39px;
            letter-spacing: 0.1px;
            color: #2A2A2A;
            border-bottom: 1px solid #E5E5E5;
            padding-bottom: 24px;
            word-break: break-word;
        }

        &--footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 17px;

            .footer-grid {
                display: flex;

                &__item {
                    display: flex;
                    align-items: center;

                    &:not(:last-child) {
                        padding-right: 8px;
                        margin-right: 8px;
                        border-right: 1px solid #E5E5E5;
                    }

                    span {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        color: #666666;
                        margin-right: 12px;
                    }

                    h4 {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 39px;
                        letter-spacing: 0.1px;
                        color: #2A2A2A;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &__actions {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        a {
            font-weight: 400;
            font-size: 16px;
            line-height: 158.02%;
        }

        &--left {
            display: flex;
            align-items: center;
            gap: 11px;
            padding-left: 11px;
            span {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #666666;
                word-break: break-word;
            }
        }

        &--right {
            display: flex;
            align-items: center;
            gap: 33px;
        }
    }
}

@media (max-width: 768px) {
    .question-details {
        &__card {
            &--text {
                font-size: 18px;
            }

            &--footer {
                .footer-grid {
                    &__item {
                        h4 {
                            font-size: 14px;
                            line-height: 1;
                        }
                    }
                }
            }
        }

        &__actions {
            gap: 15px;

            button {
                font-size: 13px;
            }

            a {
                font-size: 13px;
            }
        }
    }
}

@media (max-width: 500px) {
    .question-details {
        &__card {
            &--header {
                flex-direction: column-reverse;
                align-items: flex-start;
                margin-bottom: 0;

                .header-id {
                    margin-bottom: 10px;
                }
            }

            &--text {
                font-size: 14px;
                padding-bottom: 10px;
                line-height: 140%;
            }

            &--footer {
                margin-top: 10px;
                flex-direction: column;

                .footer-grid {
                    width: 100%;
                    margin-bottom: 10px;
                    border-bottom: 1px solid #E5E5E5;
                    padding-bottom: 10px;
                    flex-direction: column;

                    &__item {
                        width: 100%;

                        &:not(:last-child) {
                            margin-right: 0;
                            border-right: 0;
                            padding-right: 0;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}
</style>
