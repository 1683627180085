<template>
    <el-dialog
        v-model="visibility"
        center
        width="863px"
        :show-close="true"
        @close="$emit('onClose')"
        custom-class="comment-action-popup"
    >
        <template #title>
            <div class="comment-action-popup__title" v-if="commentPopupData">
                <img :src="commentPopupData.bankContentItem.bank.icon_path" alt="">
                <span>{{ commentPopupData.bankContentItem.bank.name }}</span>
            </div>
        </template>
        <div class="comment-action-popup__content">
            <p class="comment-action-popup__description">
                {{ $t("Comment for BANK on this question", { bank: commentPopupData.bankContentItem.bank.name }) }}
            </p>

            <div class="comment-box" :class="{'comment-invalid' : comment.length > commitMaxLength}">
                <span>{{ comment.length }}/{{ commitMaxLength }} {{ $t("Characters") }}</span>

                <el-input
                    v-model="comment"
                    :rows="9"
                    type="textarea"
                    :placeholder="$t('Leave your comment here...')"
                />
            </div>
        </div>
        <div class="comment-action-popup__footer">
            <button class="btn main-btn-outline" @click="$emit('onClose')">
                {{ $t("Cancel") }}
            </button>
            <button class="btn main-btn" :disabled="!comment || comment.length > commitMaxLength"
                    @click="submitCommentForm">
                {{ commentPopupData.actionType === ActionsTypeEnum.ADD ? $t("Add Comment") : $t("Save Comment") }}
            </button>
        </div>
    </el-dialog>
</template>

<script>
import ActionsTypeEnum from "@/store/enums/ActionsTypeEnum";

export default {
    name: "CommentActionPopup",
    props: {
        visibility: Boolean,
        commentPopupData: Object
    },
    emits: ["onCommentAction", "onClose"],
    data() {
        return {
            comment: "",
            ActionsTypeEnum,
            commitMaxLength: 200
        };
    },
    created() {
        if (this.commentPopupData.actionType === ActionsTypeEnum.EDIT) {
            this.comment = this.commentPopupData[this.commentPopupData.dataType].comment;
        }
    },
    methods: {
        submitCommentForm() {
            if (!this.comment) return;
            this.$emit("onCommentAction", { data: this.commentPopupData, comment: this.comment });
        }
    }
};
</script>

<style lang="scss">
.comment-action-popup {
    .el-dialog__body {
        padding-top: 0;
    }

    &__title {
        display: flex;
        align-items: center;

        img {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
            overflow: hidden;
            margin-right: 10px;
        }

        span {
            font-weight: 600;
            font-size: 24px;
            line-height: 158.02%;
            color: #2B2B2B;
        }
    }

    &__content {
        .comment-box {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            textarea {
                word-break: break-word;
            }

            span {
                font-weight: 300;
                font-size: 12px;
                line-height: 158%;
                color: #919191;
                margin-bottom: 3px;
            }

            .el-textarea__inner {
                background: #F8F8F8;
                border-radius: 10px;
                border-color: transparent;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
                padding: 29px 20px;

                &::placeholder {
                    color: #919191;
                }
            }

            &.comment-invalid {
                span {
                    color: #f54040;
                }

                .el-textarea__inner {
                    border-color: #f54040;
                }
            }
        }
    }

    &__description {
        font-weight: 400;
        font-size: 16px;
        line-height: 158%;
        color: #595959;
        margin-bottom: 0;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;

        button {
            height: 48px;

            &.main-btn {
                min-width: 207px;
            }

            &.main-btn-outline {
                font-weight: 400;
                font-size: 16px;
                line-height: 158.02%;
                color: #999999 !important;
            }
        }
    }

    @media (max-width: 880px) {
        --el-dialog-width: 98% !important;
    }
}

@media (max-width: 600px) {
    .comment-action-popup {
        &__title {
            img {
                width: 30px;
                height: 30px;
            }

            span {
                font-size: 18px;
                line-height: 140%;
            }
        }

        .el-dialog__body {
            padding: 20px;
        }

        &__description {
            font-size: 14px;
        }
    }
}
</style>
