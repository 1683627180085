<template>
  <div class="business-feedback-submitted">
    <a href="" @click.prevent="handleBackButton" class="back-button" v-if="isUserAuthenticated">
      <img src="/media/buying/icons/arrow-left.svg" alt="" height="14" width="8">
      {{ $t('Back to Feedback Sessions') }}
    </a>
    <div class="thank-you">
      <div class="thank-you__left">
        <div class="thank-you__left--icon">
          <img src="/media/buying/icons/completed.svg" alt="">
        </div>
        <h4>{{ $t('Thank you for submitting your feedback') }}</h4>
      </div>
      <div class="thank-you__right">
        <div class="d-flex align-items-center">
          <img src="/media/buying/icons/replied-icon.svg" width="28" alt="">
          <span>{{ $t('Session') }} #{{ feedbackDataContainer.id }}</span>
        </div>
        <router-link :to="`/business/feedback/feedback-session/${feedbackDataContainer.id}/result`" v-if="isUserAuthenticated">
          {{ $t('View Details') }}
        </router-link>
      </div>
    </div>

    <div class="stats-section" :class="{'mb-8': isUserAuthenticated}">
      <template v-for="(section, seIndex) in submittingStats" :key="seIndex">
        <div class="stats-section__col" v-if="section && section.stats && section.stats.length">
          <div class="stats-section__card">
            <div class="stats-section__card--title">
              {{ $t(section.title) }}
            </div>

            <div class="stats-section__scrollbar">
              <div class="stats-section__card--item" v-for="(stats, stIndex) in section.stats" :key="`${seIndex}-${stIndex}`">
                <div class="card-item__title">
                  <img :src="stats.icon_path" v-if="stats.icon_path" alt="">
                  <span>{{ stats.name }}</span>
                </div>
                <div class="card-item__stats">
                  <div class="stats-values score-value" :class="getStatusClass(stats.score)">
                    <div class="stats-values-label"></div>
                    <span>{{ $t('Score') }}</span>
                    <div class="stats-values__value">{{ stats.score }}</div>
                  </div>
                  <div class="stats-values">
                    <div class="stats-values-label"></div>
                    <span>{{ $t('Importance') }}</span>
                    <div class="stats-values__value">{{ stats.importance }}</div>
                  </div>
                  <div class="stats-values gap-value" :class="getGapStatusClass(stats.gap)">
                    <div class="stats-values-label"></div>
                    <span>{{ $t('Gap') }}</span>
                    <div class="stats-values__value">{{ stats.gap }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="performance-section" v-if="!isUserAuthenticated">
      <div class="performance-section__image">
        <img src="/media/buying/icons/performance-img.svg" alt="">
      </div>
      <div class="performance-section__text">
        <h1>{{ $t('Track the Service Performance of your Banks for Free') }}</h1>
        <p>{{ $t('You can easily save and generate data on all of your banking partners. Register now and explore more!') }}</p>
        <div class="performance-section__buttons">
          <button class="main-btn btn" v-if="!isUserAuthenticated" @click="handleSignUpToday">{{ $t('Sign Up Today') }}</button>
          <a class="main-btn-outline btn-outline btn" target="_blank" :href="platformConfigs.platformDomain">{{ $t('Learn More') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStatusClass, getGapStatusClass } from "@/core/helpers/GlobalHelper";
import store from "@/store";
import { PlatformConfigsModel } from "@/store/models/shared/config/PlatformConfigsModel";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";

export default {
  name: "BusinessFeedbackSubmitted",
  props: {
    feedbackDataContainer: Object
  },
  emits: ['handleBack'],
  data() {
    return {
      getStatusClass,
      getGapStatusClass,
        platformConfigs: new PlatformConfigsModel(PlatformTypeEnum.BUSINESS)
    }
  },
  computed: {
    isUserAuthenticated() {
      return store.getters.isUserAuthenticated;
    },
    submittingStats() {
      return [
        {
          title: 'Score per Bank',
          stats: this.feedbackDataContainer.completedStats.banks || []
        },
        {
          title: 'Score per Country',
          stats: this.feedbackDataContainer.completedStats.countries || []
        },
        {
          title: 'Score per Product Area',
          stats: this.feedbackDataContainer.completedStats.product_areas || []
        }
      ]
    }
  },
  methods: {
    handleSignUpToday() {
      if (this.feedbackDataContainer.isSocGen) {
        this.$router.push({ name: 'register-sg', query: { id: this.feedbackDataContainer.surveyDataId } });
      } else {
        this.$router.push({ name: 'sign-in' });
      }
    },
    handleBackButton() {
      this.$emit('handleBack');
    }
  }
}
</script>

<style scoped lang="scss">
.business-feedback-submitted {
  .back-button {
    font-weight: 400;
    font-size: 16px;
    line-height: 158.02%;
    color: #000000;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    img {
      margin-right: 8px;
      filter: brightness(0.4);
    }
    &:hover {
      color: #435BF4;
      img {
        filter: none;
      }
    }
  }
  .thank-you {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 24px 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    &__left {
      display: flex;
      align-items: center;
      &--icon {
        width: 128px;
        height: 128px;
        border: 14px solid #F5F7FE;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #EDEFFE;
        margin-right: 27px;
      }
      h4 {
        font-weight: 600;
        font-size: 24px;
        line-height: 40px;
        color: #000000;
        white-space: pre-line;
        margin-bottom: 0;
        word-break: break-word;
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      span {
        font-weight: 500;
        font-size: 28px;
        line-height: 39px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #63B775;
        margin-left: 10px;
      }
      a {
        font-weight: 400;
        font-size: 16px;
        line-height: 158.02%;
        color: #435BF4;
        margin-top: 10px;
      }
    }
  }
  .stats-section {
    margin: -12px;
    display: flex;
    &__col {
      padding: 12px;
      width: 100%;
    }
    &__card {
      background: #FFFFFF;
      border-radius: 8px;
      padding: 26px 16px 16px;
      height: 100%;

      &--title {
        font-weight: 700;
        font-size: 16px;
        line-height: 165.52%;
        color: #000000;
        margin-bottom: 26px;
      }
      &--item {
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        .card-item {
          &__title {
            margin-bottom: 16px;
            min-height: 30px;
            display: flex;
            align-items: center;
            img {
              width: 30px;
              height: 30px;
              margin-right: 2px;
              border-radius: 50%;
            }
            span {
              font-weight: 600;
              font-size: 14px;
              line-height: 158.02%;
              color: #2B2B2B;
              margin-left: 10px;
            }
          }
          &__stats {
            display: flex;
            justify-content: space-between;
            border-bottom: 2px solid rgba(0, 0, 0, 0.1);
            padding-left: 10px;
            padding-right: 10px;

            .stats-values {
              height: 40px;
              position: relative;
              display: flex;
              align-items: center;
              padding-left: 16px;
              min-width: 100px;
              margin-bottom: -2px;
              .stats-values-label {
                position: absolute;
                background-color: #CED4F8;
                border-radius: 50%;
                width: 8px;
                height: 8px;
                left: 0;
                &:after {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0;
                  right: 0;
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: 6px;
                }
              }
              &:before {
                content: "";
                position: absolute;
                height: 4px;
                background: transparent;
                bottom: 0;
                left: 0;
                right: 0;
                border-radius: 10px;
              }
              span {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
                opacity: 0.5;
                margin-right: 4px;
              }
              &__value {
                font-weight: 700;
                font-size: 16px;
                line-height: 13px;
                color: #000000;
                opacity: 0.87;
              }

              &.score-value {
                &.status-success {
                  &:before {
                    background: #2BC490;
                  }
                  .stats-values-label {
                    background-color: #2BC490;
                  }
                }
                &.status-danger {
                  &:before {
                    background: #E22D21;
                  }
                  .stats-values-label {
                    background-color: #E22D21;
                  }
                }
                &.status-warning {
                  &:before {
                    background: #FAD03B;
                  }
                  .stats-values-label {
                    background-color: #FAD03B;
                  }
                }
              }
              &.gap-value {
                &.status-success .stats-values-label {
                  background-color: #DBF7ED;
                  &:after {
                    background-image: url("/media/buying/icons/gap-value-icon.svg");
                    filter: invert(66%) sepia(12%) saturate(2047%) hue-rotate(108deg) brightness(93%) contrast(97%);
                  }
                }
                &.status-danger .stats-values-label {
                  background-color: #FEF4F4;
                  &:after {
                    background-image: url("/media/buying/icons/gap-value-icon.svg");
                    filter: invert(20%) sepia(88%) saturate(3117%) hue-rotate(353deg) brightness(96%) contrast(86%);
                  }
                }
                &.status-warning .stats-values-label {
                  background-color: #fff7dc;
                  &:after {
                    background-image: url("/media/buying/icons/gap-value-icon.svg");
                    filter: invert(79%) sepia(100%) saturate(853%) hue-rotate(325deg) brightness(102%) contrast(96%);
                  }
                }
              }
            }
          }
        }
      }
    }
    &__scrollbar {
      max-height: 494px;
      overflow-y: auto;
    }
  }
  .performance-section {
    background: #FFFFFF;
    border-radius: 12px;
    margin-top: 16px;
    padding: 34px 0;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    &__image {
      width: 33.33%;
      padding-right: 12px;
      padding-left: 7px;
      img {
        width: 100%;
      }
    }
    &__text {
      padding-left: 40px;
      padding-right: 15px;
      h1, p {
        font-family: 'Quantico', sans-serif;
        font-style: normal;
        word-break: break-word;
      }
      h1 {
        font-weight: 400;
        font-size: 24px;
        line-height: 39px;
        letter-spacing: 0.1px;
        color: #000000;
        margin-bottom: 16px;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.1px;
        color: #000000;
        opacity: 0.5;
      }
    }
    &__buttons {
      display: flex;
      gap: 8px;
      margin-top: 27px;
      .btn {
        min-width: 215px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .main-btn-outline {
        border: 1px solid #435BF4;
      }
    }
  }

  @media (max-width: 1300px) {
    .stats-section {
      flex-wrap: wrap;
      justify-content: center;
      &__col {
        width: 50%;
      }
    }
  }

  @media (max-width: 900px) {
    .thank-you {
      &__left {
        h4 {
          font-size: 20px;
          line-height: 140%;
        }
        &--icon {
          width: 100px;
          height: 100px;
          img {
            width: 50px;
          }
        }
      }
      &__right {
        img {
          width: 20px;
        }
        span {
          font-size: 18px;
        }
      }
    }
    .stats-section {
      &__col {
        width: 100%;
      }
    }
    .performance-section {
      flex-direction: column;
      align-items: flex-start;
      &__image {
        width: auto;
        max-width: 50%;
      }
      &__text {
        padding-left: 15px;
      }
    }
  }

  @media (max-width: 768px) {
    .thank-you {
      flex-direction: column;
      &__left {
        width: 100%;
      }
      &__right {
        width: 100%;
        justify-content: flex-end;
      }
    }
    .performance-section {
      &__buttons {
        flex-direction: column;
        .main-btn {
          max-width: 100%;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .thank-you {
      padding: 18px 15px;
      &__left {
        &--icon {
          width: 50px;
          height: 50px;
          border: 5px solid #F5F7FE;
          img {
            width: 30px;
          }
        }
        h4 {
          font-size: 16px;
          white-space: revert;
        }
      }
      &__right {
      }
    }
  }

  @media (max-width: 600px) {
    .stats-section {
      &__scrollbar {
        max-height: 230px;
      }
      &__card {
        &--item {
          .card-item {
            &__title {
              margin-bottom: 2px;
            }
            &__stats {
              .stats-values {
                min-width: auto;
                height: 34px;
                span {
                  font-size: 10px;
                }
                &__value {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
    .performance-section {
      &__image {
        max-width: 100%;
      }
      &__text {
        h1 {
          font-size: 18px;
          line-height: 130%;
        }
      }
    }
  }
}
</style>
